import * as React from 'react';
import Img from "../defaults/image"

const HomeMain = () =>
{
    const [isSmall, setIsSmall] = React.useState(null);
    const [mouseOver, setMouseOver] = React.useState(false);
    React.useEffect(() =>
    {

        document.body.style.overflow = "hidden"
        if (window.innerWidth <= 930)
        {
            setIsSmall(true)
        };
        window.addEventListener("resize", onResize);

        return () =>
        {
            window.removeEventListener("resize", onResize)
        }
    }, []);

    const onResize = () =>
    {
        const small = window.innerWidth <= 930;
        if (isSmall !== small)
        {
            setIsSmall(small)
        }
    }
    return (
        <div className="home-main">
            <div className="bg1">
                <Img image="bg1" />
            </div>

            {
                !isSmall && <div style={!mouseOver ? { zIndex: "-1" } : {}} className="bg2">
                    <Img image="bg2" />
                </div>
            }




            {/* <div className="home-background">
            </div> */}
            {/* {!isSmall && <div style={mouseOver ? { opacity: 1 } : {}} className="home-background2" />} */}
            <h1>Arsen Navasardi</h1>
            {!isSmall && <div onMouseOver={e => setMouseOver(true)} onMouseLeave={e => setMouseOver(false)} className="moving-background" />}
        </div>
    );
}

export default HomeMain