import React from "react"
import Layout from "../components/defaults/layout"
import SEO from "../components/defaults/seo"
import HomeMain from "../components/home/homeMain";

function IndexPage() {
  return (
    <Layout>
      <SEO title="Home" keywords={[`Navasardi`, `Navasardi Art Studio`, `Navasardi Art Studio Home`, `Art Studio Yerevan`, `Art Studio Armenia`]} />
      <HomeMain />
    </Layout>
  )
}
export default IndexPage
